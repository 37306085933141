<template>
    <div class="grid-cols-4 w-full max-h-[250px] h-[250px] absolute z-60 bg-white hidden md:grid" v-if="active"
         @mouseenter="menuShown(true)" @mouseleave="menuShown(false)">
        <div class="col-span-3 flex flex-col flex-wrap h-[250px] m-4">
            <div class="mb-4 px-1">
                <Link @click="menuShown(false)" :href="`/kategoriler/tum-urunler`"><h3 class="font-semibold text-lg">Tüm
                    Ürünler</h3></Link>
            </div>
            <template v-for="(menu, index) in menuItems" :key="index">
                <div class="mb-4 px-1">
                    <h3 class="font-semibold text-lg">
                        <Link v-if="menu.data.category_slug" @click="menuShown(false)" :href="`/kategoriler/${menu.data.category_slug}`">
                            {{ menu.label }}
                        </Link>
                        <a target="_blank" v-if="menu.data.url" @click="menuShown(false)" :href="`${menu.data.url}`">
                            {{ menu.label }}
                        </a>
                    </h3>
                    <ul class="mt-2 text-base" v-for="(menuSubItem, subIndex) in menu.children" :key="subIndex">
                        <li class="text-gray-700">

                            <Link v-if="menuSubItem.type=='external-link'" target="_blank" @click="menuShown(false)" :href="`/kategoriler/${menuSubItem.data.url}`">
                                {{ menuSubItem.label }}
                            </Link>
                            <Link v-if="menuSubItem.type=='alt-kategori'" @click="menuShown(false)" :href="`/kategoriler/${menuSubItem.data.category_slug}`">
                                {{ menuSubItem.label }}
                            </Link>

                        </li>
                    </ul>
                </div>
            </template>
            <div>
                <a href="https://kiralabunu.com/" target="_blank">
                    <img class="w-full max-w-[150px]"
                         src="https://kiralabunu.com/images/logo.png" alt="">
                </a>
            </div>
        </div>
        <div class="h-[250px] col-span-1 hidden ts:block">
            <Link href="">
                <img src="../../../images/banner/mini-menu.png" alt="" class="object-contain" />
            </Link>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    name: "Menu",
    props: {
        active: {
            type: Boolean,
            default: true
        },
        menuItems: Object
    },
    emits: ["menuchange"],
    methods: {
        menuShown(val) {
            //console.log('close');
            this.$emit("menuchange", val);
        }
    },
    components: {
        Link
    }
};
</script>

<style scoped></style>
